import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import ImageWrap from 'components/ImageWrap'

export default function IndustriesSingleTemplate({ data }) {
  const page = data.wpPage

  return (
    <Layout
      bodyClassName='industry-template-default single single-industry postid-189 automotive app-data index-data singular-data single-data single-industry-data single-industry-automotive-data'
      sectionClassName='single single--dark single-industry'
      page={page}
    >
      <section className='single single--dark single-industry'>
        {/* <BannerImageOnly content={page.template.bannerImageOnly} /> */}
        <SplitBlock content={page.template.industriesSingle.splitBlock} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoPage
      ...BannerPage
      ...RelatedBlockPage
      ...ContactBlockPage
      template {
        templateName
        ... on WpTemplate_IndustriesSingle {
          industriesSingle {
            splitBlock {
              summary {
                icon {
                  ...GatsbyImage
                }
                title
                body
              }
              stats {
                title
                list {
                  number
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`

const SplitBlock = ({ content }) => {
  const { summary, stats } = content
  return (
    <section className='multivariate-grid multivariate-grid--horizontal-clip tile-stats-50-50 tile-stats-50-50--pull-up'>
      <div className='tile-stats-50-50__container multivariate-grid__container'>
        <div className='tile-stats-50-50__row multivariate-grid__row'>
          <div className='tile-stats-50-50__column multivariate-grid__column'>
            <div className='content-image-card'>
              <div className='content-image-card__icon-wrap'>
                {summary.icon && <ImageWrap image={summary.icon} />}
              </div>
              <h4 className='content-image-card__title'>{summary.title}</h4>
              <div className='content-image-card__content'>
                <div
                  className='content-image-card__excerpt'
                  dangerouslySetInnerHTML={{ __html: summary.body }}
                />
              </div>
            </div>
          </div>
          <div className='tile-stats-50-50__column multivariate-grid__column'>
            <h5 className='tile-stats-50-50__title'>{stats.title}</h5>
            <ul className='list'>
              {stats.list &&
                stats.list.map((row, i) => (
                  <li key={i} className='list__item'>
                    <span className='item__number item__number--top'>
                      {row.number}
                    </span>
                    <span>{row.description}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
